"use client";

import { styled } from "styled-components";
import { breakpointDesktop, Link } from "@moller/design-system";
import NextLink from "../nextLink";

const MenuSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: var(--moller-spacing-xs);
  min-width: 190px;
  &:not(:first-of-type) {
    margin-top: var(--moller-spacing-l);
  }

  @media screen and (min-width: ${breakpointDesktop}) {
    &:not(:first-of-type) {
      margin-top: 0;
    }
  }
`;

const SectionTitle = styled.p`
  font-size: var(--moller-typeface-size-xxs);
`;

const NavLink = styled(Link)`
  text-decoration: none;
  line-height: var(--moller-typeface-line-height);
  font-weight: 500;
  color: var(--moller-color-on-background);
`;

const Divider = styled.hr`
  display: flex;
  border: none;
  height: 0.5px;
  background-color: var(--moller-color-outline);
  width: calc(100vw - calc(var(--moller-spacing-l) * 2));
  margin: var(--moller-spacing-base) 0 0;

  @media screen and (min-width: ${breakpointDesktop}) {
    display: none;
  }
`;

interface MenuSectionProps {
  departments: { name: string; slug: string }[];
  startLetter: string;
  endLetter: string;
  closeMenu: () => void;
}

export const MenuSection = ({
  departments,
  startLetter,
  endLetter,
  closeMenu,
}: MenuSectionProps) => {
  const filteredDepartments = departments.filter(
    (department) =>
      department.name.charAt(0).toUpperCase() >= startLetter &&
      department.name.charAt(0).toUpperCase() <= endLetter,
  );

  return (
    <MenuSectionWrapper>
      <div>
        <SectionTitle>{`${startLetter}-${endLetter}`}</SectionTitle>
        <Divider />
      </div>
      {filteredDepartments.map((department) => (
        <NavLink
          key={department.slug}
          $as={NextLink}
          href={`/forhandler/${department.slug}`}
          onClick={() => closeMenu()}
        >
          {department.name}
        </NavLink>
      ))}
    </MenuSectionWrapper>
  );
};
