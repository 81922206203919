import { ComponentProps, FC } from "react";
import { Icon, Link } from "@moller/design-system";
import { styled } from "styled-components";

type Props = ComponentProps<typeof Link>;

const StyledLink = styled(Link)`
  display: inline-flex;
  text-decoration: none;
  color: var(--moller-color-on-inverse-background);
  transition: 0.3s ease-in-out transform;

  .arrow {
    margin-left: var(--moller-spacing-base);
  }

  &:hover .arrow {
    transform: translateX(3px);
  }
`;

export const ArrowLink: FC<Props> = ({ children, ...rest }) => {
  return (
    <StyledLink {...rest}>
      <span>{children}</span>
      <Icon className="arrow" icon="arrow_forward" />
    </StyledLink>
  );
};
